<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号码">
              <a-input v-model.trim="queryParam['%invoice_number']"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票代码">
              <a-input v-model="queryParam['%invoice_code']"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票金额">
              <a-input v-model="queryParam['%amount']"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '新建发票' : '编辑发票'"
      @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="发票号码" prop="invoice_number">
              <a-input v-model.trim="form.invoice_number"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="开票日期" prop="invoice_date">
              <a-date-picker class="full-width" v-model="form.invoice_date" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="校验码" prop="check_code">
              <a-input v-model="form.check_code" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="发票代码" prop="invoice_code">
              <a-input v-model="form.invoice_code" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="发票类型" prop="invoice_type">
              <a-select v-model="form.invoice_type">
                <a-select-option :key="0">普通发票</a-select-option>
                <a-select-option :key="1">专用发票</a-select-option>
                <a-select-option :key="2">缴款单</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="发票金额" prop="amount">
              <a-input v-model="form.amount" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税率" prop="tax_rate">
              <a-input v-model="form.tax_rate" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税额" prop="tax">
              <a-input v-model="form.tax" />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item label="登记日期" prop="register_date">
              <a-date-picker class="full-width" v-model="form.register_date" />
            </a-form-model-item>
          </a-col> -->
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import moment from 'moment'
import { deleteCommonInfo, getCommonInvoicePage, saveCommonInfo, updateInvoiceCommonInfo } from '@/api/common'
export default {
  components: {
    STable
  },
  data() {
    return {
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '发票号码',
          dataIndex: 'invoice_number'
        },
        {
          title: '开票日期',
          dataIndex: 'invoice_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '校验码',
          dataIndex: 'check_code'
        },
        {
          title: '发票代码',
          dataIndex: 'invoice_code'
        },
        {
          title: '发票类型',
          dataIndex: 'invoice_type',
          customRender: (text) => {
            return text === 0 ? '普通发票' : (text === 1 ? '专用发票' : '缴款单')
          }
        },
        {
          title: '发票金额',
          dataIndex: 'amount',
          customRender: text => {
            return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '税额',
          dataIndex: 'tax',
          customRender: text => {
            return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '税率',
          dataIndex: 'tax_rate'
        },
        // {
        //   title: '登记日期',
        //   dataIndex: 'register_date',
        //   customRender: (text) => {
        //     return text && moment(text).format('YYYY/MM/DD')
        //   },
        //   width: 120
        // },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        invoice_number: [{ required: true, message: '请填写发票号码', trigger: 'blur' }],
        amount: [{ required: true, message: '请填写发票金额', trigger: 'blur' }],
        // tax_rate: [{ required: true, message: '请填写发票税率', trigger: 'blur' }],
        // tax: [{ required: true, message: '请填写发票税额', trigger: 'blur' }],
        invoice_type: [{ required: true, message: '请填写发票类型', trigger: 'blur' }],
        invoice_date: [{ required: true, message: '请选择开票日期', trigger: 'blur' }]
      },
      selectedRowKeys: [],
      loadData: parameter => {
        return getCommonInvoicePage(this.moduleName, Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      form: {
        invoice_number: '',
        invoice_date: null,
        check_code: '',
        invoice_code: '',
        invoice_type: 0,
        amount: '',
        tax_rate: '',
        tax: '',
        register_date: null
      },
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create',
      moduleName: 'invoice_registration'
    }
  },
  methods: {
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        invoice_number: '',
        invoice_date: null,
        check_code: '',
        invoice_code: '',
        invoice_type: 0,
        amount: '',
        tax_rate: '',
        tax: '',
        register_date: null
      }
      this.formVisible = true
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
      })
    },
    handleNameChange() {
      this.form.abbr = getFirstLetter(this.form.name)
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.form = record
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteCommonInfo(this.moduleName, { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.invoice_type !== 2) {
            if (!this.form.tax_rate) {
              this.$message.error('请填写税率')
              return
            }
          }
          let func = saveCommonInfo
          if (this.modalFlag === 'update') {
            func = updateInvoiceCommonInfo
          }
          const data = this.form
          data.invoice_date = data.invoice_date ? moment(data.invoice_date).valueOf() : null
          data.register_date = data.register_date ? moment(data.register_date).valueOf() : null
          func(this.moduleName, data).then(_ => {
            this.confirmLoading = false
            this.formVisible = false
            this.$notification['success']({
              message: '提示',
              description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
            })
            if (this.modalFlag === 'create') {
              this.$refs.table.refresh(true)
            }
          })
        } else {
          return false;
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
